import React, { useState } from "react"

import { ReCaptcha } from "react-recaptcha-v3"
import { useForm } from "react-hook-form"
import webinar_img from "../imgs/webinar_form.jpg"
import { Link } from "gatsby"

const ContactForm = () => {
  const [firstname, setFirstname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [company, setCompany] = useState("")
  const [jobtitle, setJobTitle] = useState("")
  const { register, handleSubmit, errors, reset } = useForm()
  const portalID = process.env.GATSBY_HUBSPOT_PORTAL_ID
  const formID = process.env.GATSBY_HUBSPOT_FORM_WEBINAR_ID
  const DEFAULT_SEND_MESSAGE = "Save my spot"
  const [reCaptchaToken, setRecaptchaToken] = useState("")
  const [flashMessage, setFlashMessage] = useState("")
  const [flashClass, setFlashClass] = useState("form__result")
  const [sendMessage] = useState(DEFAULT_SEND_MESSAGE)
  const [disabled] = useState(false)

  const submitForm = e => {
    var xhr = new XMLHttpRequest()
    var url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`
    var data = {
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: firstname,
        },
        {
          name: "phone",
          value: phone,
        },
        {
          name: "company",
          value: company,
        },
        {
          name: "jobtitle",
          value: jobtitle,
        },
      ],
      context: {
        pageUri: "www.mangochango.com",
        pageName: "MangoChango",
      },
    }
    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setFlashMessage("Message sent, we will contact you soon.")
        setFlashClass(" form__result success")
        reset()
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        setFlashMessage("There was an error sending your message.")
        setFlashClass(flashClass + " error")
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        setFlashMessage("There was an error sending your message.")
        setFlashClass(flashClass + " error")
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        setFlashMessage("There was an error sending your message.")
        setFlashClass(flashClass + " error")
      }
    }

    xhr.send(final_data)
  }

  const verifyCallback = token => {
    setRecaptchaToken(token)
  }

  return (
    <div>
      <ReCaptcha
        action="contact_us"
        sitekey={process.env.GATSBY_RECAPTCHA_KEY}
        verifyCallback={verifyCallback}
      />
      {/* Contact */}
      <section className="big-hero webinar">
        <div className="container">
          <div className="row py-5">
            <div className="col col__12">
              <span className="big-hero__subtitle text-primary">Webinar</span>
              <h1 className="title text-secondary">
                Selecting the Right Offshore Software Partner
              </h1>
            </div>
            <div className="col col__12">
              <p className="big-hero__description text-black">
                <span className="big-hero__description-date">March 27th</span>{" "}
                <br />
                2:00pm EST <br />
                <strong>1:00pm CDT (Central Daylight Time)</strong> <br />
                11:00am PST
              </p>
              <br />
              <span className="big-hero__subtitle text-primary">Speaker</span>
              <p className="big-hero__description text-black">
                <strong>Rene Larrave</strong>: Chairman of MangoChango
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="webinar__form bg-secondary--fade">
        <div className="container">
          <div className="row webinar__form__reverse">
            <div className="col col__6">
              <div className="contact__intro">
                <img
                  src={webinar_img}
                  alt=""
                  title=""
                  style={{
                    height: "auto",
                    maxWidth: 100 + "%",
                    marginBottom: 20 + "px",
                  }}
                />
                <p className="contact__description">
                  Choosing the right offshore software partner is key to scaling
                  your business and ensuring project success. Join{" "}
                  <a
                    href="https://www.linkedin.com/in/larrave/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rene Larrave
                  </a>
                  , Chairman of MangoChango and Professor of Entrepreneurship at
                  SMU, for an exclusive webinar on how to evaluate and select
                  the best offshore development teams.
                </p>
                <p className="contact_description">
                  Gain expert insights on assessing technical expertise,
                  communication, cultural fit, and cost efficiency. Plus, learn
                  best practices from Rene’s experience negotiating on both
                  sides of the table.
                </p>
              </div>
            </div>
            <div className="col col__6">
              <div className="contact__form form">
                <form method="post" onSubmit={handleSubmit(submitForm)}>
                  <input
                    className="sr-only"
                    type="text"
                    name="g-recaptcha-response"
                    ref={register}
                    style={{ visibility: "hidden" }}
                    defaultValue={reCaptchaToken}
                  />

                  <p className="form__heading">Register Free for Webinar</p>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="full-name"
                        type="text"
                        placeholder="Full Name"
                        ref={register({ required: true })}
                        onChange={e => setFirstname(e.target.value)}
                      />

                      {errors["full-name"] && (
                        <p className="form__result error">
                          Full Name field is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="company"
                        type="text"
                        placeholder="Company Name"
                        onChange={e => setCompany(e.target.value)}
                        ref={register({ required: true })}
                      />
                      {errors.company && (
                        <p className="form__result error">
                          Company is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="jobtitle"
                        placeholder="Job Title"
                        onChange={e => setJobTitle(e.target.value)}
                        ref={register({ required: true })}
                      />
                      {errors.message && (
                        <p className="form__result error">
                          Job Title is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="email"
                        placeholder="Business Email"
                        ref={register({
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        onChange={e => setEmail(e.target.value)}
                      />
                      {errors.email && (
                        <p className="form__result error">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <input
                        disabled={disabled ? "disabled" : ""}
                        name="phone"
                        placeholder="Phone Number"
                        onChange={e => setPhone(e.target.value)}
                        ref={register({ required: true })}
                      />
                      {errors.phone && (
                        <p className="form__result error">Phone is required</p>
                      )}
                    </div>
                  </div>
                  <div className="form__row">
                    <div className="form__item">
                      <p className="privacy__policy-description">
                        By submitting this form you understand MangoChango will
                        process your information in accordance with our{" "}
                        <Link to="/privacy-policy">Privacy Policy</Link>.
                      </p>
                      <p className="privacy__policy-description">
                        I agree that by signing up for this event, I am
                        expressly consenting to provide my registration
                        information, including name, email, etc., to MangoChango
                        and to the co-sponsor(s) of the event. MangoChango and
                        each co-sponsor may use my information in accordance
                        with their privacy policy (see the event details for
                        applicable privacy policies). MangoChango{" "}
                        <Link to="/privacy-policy">Privacy Policy</Link>.
                      </p>
                    </div>
                  </div>
                  <div className="form__row form__row--end">
                    <p className={flashClass}>{flashMessage}</p>
                    <button
                      disabled={disabled ? "disabled" : ""}
                      className="button button--large button--icon-end"
                    >
                      {sendMessage}
                      <span className="icon material-icons">
                        arrow_right_alt
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact */}
    </div>
  )
}
export default ContactForm
