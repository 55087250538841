import React from "react"
import Webinar from "../components/webinar"
import SEO from "../components/seo"
import Layout from "../components/layout"
import webinarImage from "../imgs/webinar_form.jpg"

const ContactUs = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Webinar: Selecting the Right Offshore Software Partner"
      description="Choosing the right offshore software partner is key to scaling your business and ensuring project success. Join Rene Larrave, Chairman of MangoChango and Professor of Entrepreneurship at SMU, for an exclusive webinar on how to evaluate and select the best offshore development teams."
      image={webinarImage}
    ></SEO>
    <Webinar></Webinar>
  </Layout>
)

export default ContactUs
